import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import Marquee from 'react-fast-marquee'
// import dyImg from '../../../assets/img/projects/crypto-art-driver/dy.png'
import dyCommitsImg from '../../../assets/img/projects/crypto-art-driver/commits.svg'
import dyViewsImg from '../../../assets/img/projects/crypto-art-driver/views.svg'
import interpretImg from '../../../assets/img/projects/crypto-art-driver/002.png'
import aboutImg from '../../../assets/img/projects/crypto-art-driver/003.png'
import issued1Img from '../../../assets/img/projects/crypto-art-driver/issued-1.jpg'
import issued2Img from '../../../assets/img/projects/crypto-art-driver/issued-2.jpg'
import issued3Img from '../../../assets/img/projects/crypto-art-driver/issued-3.jpg'
import member1Img from '../../../assets/img/projects/crypto-art-driver/member-1.png'
import member2Img from '../../../assets/img/projects/crypto-art-driver/member-2.jpg'
import member3Img from '../../../assets/img/projects/crypto-art-driver/member-3.jpg'
import member4Img from '../../../assets/img/projects/crypto-art-driver/member-4.jpg'
import member5Img from '../../../assets/img/projects/crypto-art-driver/member-5.jpg'
import member6Img from '../../../assets/img/projects/crypto-art-driver/member-6.jpg'
import comp1Img from '../../../assets/img/projects/crypto-art-driver/comp-1.png'
import comp2Img from '../../../assets/img/projects/crypto-art-driver/comp-2.png'
import comp3Img from '../../../assets/img/projects/crypto-art-driver/comp-3.png'
import dy1Img from '../../../assets/img/projects/crypto-art-driver/dy-1.png'
import dy2Img from '../../../assets/img/projects/crypto-art-driver/dy-2.png'
import dy3Img from '../../../assets/img/projects/crypto-art-driver/dy-3.png'
import qrImg from '../../../assets/img/projects/crypto-art-driver/qr.png'
import { useDetectMobile } from '../../../utils'
import './style.scss'
import axios from 'axios'

function shortAddress(address: string): string {
  if (address.length < 14) return address
  return `${address.slice(0, 7)}...${address.slice(-7)}`
}

const StyledBlcok = styled.div`
  margin: 100px 0;

  @media screen and (max-width: 600px) {
    margin: 75px 0;
  }
`

const Entry: React.FC = ({ children }) => {
  return (
    <div className="entry container">
      <div className="img">
        <img src={qrImg} alt="" />
      </div>
      <div className="text">开始建设知识库</div>
    </div>
  )
}
const CompData = [
  {
    img: comp1Img,
    title: '佘璐芸｜当代艺术驱动器作者',
    desc: '艺术家，女，1997年生于广东深圳。2018年至今就读于中央美术学院实验艺术。',
  },
  {
    img: comp2Img,
    title: '数字化合物',
    desc: '数字化合物是一家致力于推动“加密原生艺术”发展的机构。数字化合物通过联合创作、品牌创作、展览策划、公众教育、学术研究等工作，推广基于区块链技术的优秀艺术家和项目，推动加密艺术行业发展。',
  },
  {
    img: comp3Img,
    title: 'Nervina Labs',
    desc: '秘宝是国内最多元的加密文化聚集地，聚合了一系列视觉艺术家、独立音乐人、潮玩文创IP、电影视频发行、数字藏品确权商，通过为不同领域提供NFT铸造、发行、设计、技术支持等一站式服务，传递加密文化。秘宝旨在链接各类品牌，搭建通往元宇宙的桥梁。现如今，秘宝已经帮助电影、文创、艺术、音乐、动漫、游戏、体育等行业打开了NFT数字藏品的大门，为各类企业提供NFT综合解决方案，打造属于品牌自己的元宇宙生态。',
  },
]
const Compound: React.FC = ({ children }) => {
  const [isMobile] = useDetectMobile()

  return (
    <div className="compound container">
      <div className="members">
        <img src={member1Img} alt="" />
        <img src={member2Img} alt="" />
        <img src={member3Img} alt="" />
        <img src={member4Img} alt="" />
        <img src={member5Img} alt="" />
        <img src={member6Img} alt="" />
      </div>
      <div className="infos">
        {CompData.map((comp) =>
          isMobile ? (
            <div className="dy-mobile" key={comp.title}>
              <div className="title">
                <img src={comp.img} alt="" />
                <span>{comp.title}</span>
              </div>
              <div className="desc">{comp.desc}</div>
            </div>
          ) : (
            <div className="dy" key={comp.title}>
              <div className="img">
                <img src={comp.img} alt="" />
              </div>
              <div className="info">
                <div className="title">{comp.title}</div>
                <div className="desc">{comp.desc}</div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}
const Issued: React.FC = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // variableWidth: isMobile,
  }

  return (
    <div className="issued container">
      <div className="top-img">
        <Slider {...settings} className="issue-slide">
          <div className="issue">
            <div className="bg" />
            <div className="img">
              <img src={issued1Img} alt="" />
            </div>
            <div className="info">
              <div className="t1 font-montserrat-black">100</div>
              <div className="t2">橙色发行数量</div>
            </div>
          </div>
          <div className="issue">
            <div className="bg" />
            <div className="img">
              <img src={issued2Img} alt="" />
            </div>
            <div className="info">
              <div className="t1 font-montserrat-black">900</div>
              <div className="t2">蓝色发行数量</div>
            </div>
          </div>
          <div className="issue">
            <div className="bg" />
            <div className="img">
              <img src={issued3Img} alt="" />
            </div>
            <div className="info">
              <div className="t1 font-montserrat-black">9000</div>
              <div className="t2">灰色发行数量</div>
            </div>
          </div>
        </Slider>
      </div>
      <div className="desc">
        <p className="high">NFT共发行10,000件，全部以盲盒形式发售。</p>
        <p>
          盲盒中灰色总量9,000件，可以为词库增加1个权重为1的词；蓝色总量900件，可以为词库增一个权重为3的词（相当于把一个词增加了三遍）；橙色总量100件，可以增加一个占词库当前总权重1%的词。
        </p>
      </div>
    </div>
  )
}
const About: React.FC = ({ children }) => {
  return (
    <div className="about container">
      <div className="top-img">
        <img src={aboutImg} alt="" />
      </div>
      <div className="desc">
        <p className="high">「共同知识库」是该作品中的关键概念。</p>
        <p>
          初始的300个当代艺术词汇组成底层知识库。参与者每拥有一个NFT，就可以为知识库增加一个词，添加词汇的过程就是共同知识库逐步去中心化的过程。NFT持有者规则范围内的任何意志将公开不可篡改，被公链记录并捍卫。
        </p>
      </div>
    </div>
  )
}
const Interpret: React.FC = ({ children }) => {
  return (
    <div className="interpret container">
      <div className="top-img">
        <img src={interpretImg} alt="" />
      </div>
      <div className="desc">
        <p className="high">
          「加密艺术驱动器」是数字化合物推出的加密原生艺术作品，由10,000件NFT组成。
        </p>
        <p>
          每件NFT的持有者有权在Nervos公链上通过行权，为词库增加一个词汇。NFT持有者将根据打开作品的时间不同而多次获得驱动器屏幕显示的随机词组，并有1次机会将喜欢的词组固定在屏幕上，这个动作也将被区块链确认，无法更改。
        </p>
        <p className="high">
          「加密艺术驱动器」把链上共建、参与者博弈作为艺术语言，赋予参与者权力，与创作者共同构建链上去中心化的「共同知识库」。
        </p>
        <p>
          NFT持有者在元规则下拥有充分的主权和自由，用艺术的方式回应什么是「公链」、什么是「共同知识库」，以及如何在「共识」的机制下参与创造数字生活。
        </p>
      </div>
    </div>
  )
}

interface Idynamics {
  image: string
  title: string
  desc: string
  datetime: string
  data?: {
    view: number
    commit: number
  }
  url?: string
}

// TODO:
const dynamics: Idynamics[] = [
  {
    image: dy1Img,
    title: '数字化合物开放Discord社群｜白名单+空投等你来领',
    desc: '新年已来到，数字化合物向各位问好。在新年到来之际，我们初步搭建好了DIscord社群，里面有有超棒的加密艺术伙伴和项目。感谢大家的关注，我们特别设置了两个新年福利活动，欢迎朋友们积极参与。',
    datetime: '2022-01-07 17:04',
    url: 'https://mp.weixin.qq.com/s?__biz=MzkzNzI4NzEyMg==&mid=2247484133&idx=1&sn=fa8fbc983627da838339e156e44b41a8&chksm=c2908e58f5e7074ea2b3b040c53f546c77a8dc8c668a49f63f435ca587a7879fd5596abf3030&token=676664352&lang=zh_CN#rd',
    // data: {
    //   view: 1000,
    //   commit: 1000,
    // },
  },
  {
    image: dy2Img,
    title: '2022元宇宙科技文化节主题论坛｜线上，新的现场',
    desc: '21世纪初，互联网在全球快速普及，艺术发生在新的现场，屏幕奇观不断改造着用户的感官体验，形成了今天的视觉文化生态。区块链技术推动的web3向我们描绘了一个乌托邦愿景，以此而构建的“元宇宙”吸引着从创作者到投资人的快速涌入，艺术也在这样的情境中变得不同。',
    datetime: '2022-01-06 20:00',
    url: 'https://mp.weixin.qq.com/s?__biz=MzkzNzI4NzEyMg==&mid=2247484123&idx=1&sn=1715255ad39ab737a5b8109d027436ba&chksm=c2908e66f5e70770d13a478541342128bceddbd67480ff8aaff583dcc119a5cd54602a1e2f61&token=676664352&lang=zh_CN#rd',
  },
  {
    image: dy3Img,
    title: '用加密原生艺术的方式表达公链是什么——《加密艺术驱动器》创作始末',
    desc: '三个多月前郭成和王博聊天中提到了一个作品叫《当代艺术驱动器》，两人觉得这个作品很有趣，于是Nervina和数字化合物决定找到作者佘璐芸一起做项目。第一轮沟通下来我觉得只把《当代艺术驱动器》在链上复制一遍不够酷。我们可以走的更远。',
    datetime: '2022-01-07 17:20',
    url: 'https://mp.weixin.qq.com/s?__biz=MzkzNzI4NzEyMg==&mid=2247484146&idx=1&sn=339f559a69b00249ad497dbaa23637d7&chksm=c2908e4ff5e70759308cef0160e895d4ddb0aea6a4f01837eb0bb5a3b276bfcbde073b2f4c0a&token=676664352&lang=zh_CN#rd',
  },
]

const Dynamics: React.FC = ({ children }) => {
  return (
    <div className="dynamics container">
      {dynamics.length ? (
        dynamics.map((dy, i) => (
          <a className="dy" key={i} href={dy.url}>
            <div className="img">
              <img src={dy.image} alt="" />
            </div>
            <div className="info">
              <div className="title">{dy.title}</div>
              <div className="desc">
                {dy.desc.length > 100 ? dy.desc.slice(0, 100) + '...' : dy.desc}
              </div>
              <div className="data">
                <span>{dy.datetime}</span>
                {dy.data && (
                  <span>
                    <img src={dyCommitsImg} alt="" />
                    <span>{dy.data.commit}</span>
                    <img src={dyViewsImg} alt="" />
                    <span>{dy.data.view}</span>
                  </span>
                )}
              </div>
            </div>
          </a>
        ))
      ) : (
        <div className="dy empty">敬请期待</div>
      )}
    </div>
  )
}

// TODO: 默认地址
const defaultAddress: string[] = []

const ProjectCryptoArtDriver: React.FC = ({ children }) => {
  const [addresses, setAddresses] = useState<string[]>(defaultAddress)
  useEffect(() => {
    axios
      .get('https://mad-api.apps.nervina.cn/words_creators')
      .then((res) => {
        console.log(res.data)
        setAddresses(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  })
  return (
    <div id="cryptoArtDriver">
      <div className="theme-block">
        <div className="content">
          <div className="container">
            <div className="t1 font-dinpro-regular">
              加密艺术驱动器 <small>/ Crypto Art Drive</small>
            </div>
            <div className="t2 font-dinpro-regular">
              词库撰写人 <small>/ Thesuarus Writer</small>
            </div>
            <div className="more">
              {/* TODO: /projects/crypto-art-driver/addresses */}
            </div>
          </div>
          {addresses.length > 0 && (
            <Marquee className="fixed-marquee font-oswald-stencil">
              {addresses.map((address, i) => (
                <span key={i}>{shortAddress(address)}</span>
              ))}
            </Marquee>
          )}
        </div>
      </div>
      <StyledBlcok>
        <div className="block-title font-dinpro-regular">
          <span>001</span>
        </div>
        <div className="block-subtitle font-dinpro-regular">
          DYNAMICS 项目动态
        </div>
        <Dynamics />
      </StyledBlcok>
      <StyledBlcok>
        <div className="block-title font-dinpro-regular">
          <span>002</span>
        </div>
        <div className="block-subtitle font-dinpro-regular">
          INTERPRET 作品阐释
        </div>
        <Interpret />
      </StyledBlcok>
      <StyledBlcok>
        <div className="block-title font-dinpro-regular">
          <span>003</span>
        </div>
        <div className="block-subtitle font-dinpro-regular">
          ABOUT 关于「共同知识库」
        </div>
        <About />
      </StyledBlcok>
      <StyledBlcok>
        <div className="block-title font-dinpro-regular">
          <span>004</span>
        </div>
        <div className="block-subtitle font-dinpro-regular">
          ISSUED 发行信息
        </div>
        <Issued />
      </StyledBlcok>
      <StyledBlcok>
        <div className="block-title font-dinpro-regular">
          <span>005</span>
        </div>
        <div className="block-subtitle font-dinpro-regular">
          COMPOUND 化合作用
        </div>
        <Compound />
      </StyledBlcok>
      <StyledBlcok>
        <div className="block-title font-dinpro-regular">
          <span>006</span>
        </div>
        <div className="block-subtitle font-dinpro-regular">ENTRY 参与入口</div>
        <Entry />
      </StyledBlcok>
    </div>
  )
}

export default ProjectCryptoArtDriver
