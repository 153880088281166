import React from 'react'
import { HomeTheme } from './theme'
import { HomeFeatures } from './features'
import { HomeWorks } from './work'
import { HomeResearchs } from './research'
import { HomeTeam } from './team'
import { HomePartner } from './partner'
import './style.scss'

export const Home: React.FC = () => {
  return (
    <div id="home">
      <HomeTheme />
      <HomeFeatures />
      <HomeWorks />
      <HomeResearchs />
      <HomeTeam />
      <HomePartner />
    </div>
  )
}
