import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import CommonHomeTitle from '../../components/CommonHomeTitle'
import img1 from '../../assets/img/home_feature_1.jpg'
import img2 from '../../assets/img/home_feature_2.jpg'
import img3 from '../../assets/img/home_feature_3.jpg'

interface HomeFeatureData {
  key: string
  img: string
}

const data: HomeFeatureData[] = [
  {
    key: '1',
    img: img1,
  },
  {
    key: '2',
    img: img2,
  },
  {
    key: '3',
    img: img3,
  },
]

export const HomeFeatures: React.FC = () => {
  const { t } = useTranslation('trans')
  const [current, setCurrent] = useState<HomeFeatureData>(data[0])

  return (
    <div className="features">
      <div className="container">
        <CommonHomeTitle>ABOUT US 关于我们</CommonHomeTitle>
        <div className="tabs">
          {data.map((item) => (
            <span
              key={item.key}
              className={classnames({ active: item === current })}
              onMouseEnter={() => setCurrent(item)}
            >
              {t(`home.features.${item.key}.title`)}
            </span>
          ))}
        </div>
        {data.map((item) => (
          <div
            key={item.key}
            className={classnames('feature', { active: item === current })}
            style={{ backgroundImage: `url(${item.img})` }}
          >
            <div className="content">
              <div className="subtitle font-montserrat-black">
                {t(`home.features.${item.key}.sub_title`)}
              </div>
              <div className="desc">{t(`home.features.${item.key}.desc`)}</div>
              <div className="altDesc">
                {t(`home.features.${item.key}.alt_desc`)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
