import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'
import $ from 'jquery'
import qs from 'qs'
import CommonLink from '../../components/CommonLink'
import MobileMenuIcon from '../../components/mobileMenuIcon'
import logoImg from '../../assets/img/logo.png'
import logoBlackImg from '../../assets/img/logo-black.png'
import wechatImg from '../../assets/img/home_wechat.png'
import { useDetectMobile } from '../../utils'
import Dropdown from 'rc-dropdown'
import Menu, { Item as MenuItem } from 'rc-menu'
import 'rc-dropdown/assets/index.css'
import './style.scss'

const HEADER_TOP = 100

const Header: React.FC = ({ children }) => {
  const [isTop, setTop] = useState(true)
  const [isMobile] = useDetectMobile()
  const [moblieMenuVisible, showMobileMenu] = useState(false)
  const { t } = useTranslation('trans')

  useEffect(() => {
    const scroll = (): void => {
      const y = window.scrollY
      setTop(y < HEADER_TOP)
    }
    window.addEventListener('scroll', scroll, false)

    return () => {
      window.removeEventListener('scroll', scroll)
    }
  }, [])

  const closeMobileMenu = useCallback(() => {
    showMobileMenu(false)
  }, [showMobileMenu])

  const logo = (
    <CommonLink to="/?scroll=home">
      <img
        src={isTop && !moblieMenuVisible ? logoImg : logoBlackImg}
        alt=""
        className="logo"
      />
    </CommonLink>
  )

  const link1 = (
    <CommonLink to="/?scroll=home" onClick={closeMobileMenu}>
      {t('header.menu_3')}
    </CommonLink>
  )
  // const link2 = (
  //   <CommonLink to="wechat:///assets/images/9529d878-cb08-49ff-a001-a2bca181ed4b.jpg">
  //     {t('header.menu_2')}
  //   </CommonLink>
  // )
  const link3 = (
    <CommonLink to="/?scroll=researchs" onClick={closeMobileMenu}>
      {t('header.menu_4')}
    </CommonLink>
  )
  const link4 = (
    <CommonLink to="/?scroll=team" onClick={closeMobileMenu}>
      {t('header.menu_5')}
    </CommonLink>
  )

  const projectMenuItems = [
    <MenuItem key="1">
      <Link to="/projects/crypto-art-driver">加密艺术驱动器</Link>
    </MenuItem>,
    <MenuItem key="2">
      <Link to="/projects/digital-babel-library">数字巴别图书馆</Link>
    </MenuItem>,
  ]
  const projectMenu = (
    <Menu selectable={false} className="header-project-menu">
      {projectMenuItems}
    </Menu>
  )

  const header = isMobile ? (
    <header className={classnames({ top: isTop, opened: moblieMenuVisible })}>
      {logo}
      <MobileMenuIcon
        color={isTop && !moblieMenuVisible ? '#fff' : '#000'}
        opened={moblieMenuVisible}
        onClick={() => showMobileMenu(!moblieMenuVisible)}
      />
      <div className="mobile-menu">
        {link1}
        {link3}
        {link4}
      </div>
    </header>
  ) : (
    <header className={classnames({ top: isTop })}>
      <div className="container">
        <div className="left">{logo}</div>
        <div className="right">
          <Dropdown overlay={projectMenu}>{link1}</Dropdown>
          {link3}
          {link4}
        </div>
      </div>
    </header>
  )

  return header
}

const Footer: React.FC = ({ children }) => {
  const { t } = useTranslation('trans')

  return (
    <footer>
      <div className="container">
        <div className="content">
          <div className="row1">
            <div className="title">合作伙伴</div>
            <div className="links">
              <div className="link">
                <a href="https://www.nervos.org/">Nervos</a>
              </div>
              <div className="link">
                <a href="https://v.mibao.net/zh">Nervina Labs</a>
              </div>
              <div className="link">
                <a href="http://www.lumei.edu.cn/dlxq/jy/jxjg_INSTITUTION/cmdhxy.htm">
                  鲁迅美术学院传媒动画学院
                </a>
              </div>
            </div>
          </div>
          <div className="row1">
            <div className="title">社交媒体</div>
            <div className="links">
              <div className="link">
                <a href="https://weibo.com/7698895315/profile?topnav=1&wvr=6">
                  微博 | 数字化合物
                </a>
              </div>
              <div className="link">
                <a href="https://twitter.com/Digital_Compoun">
                  Twitter | Digital_Compoun
                </a>
              </div>
              <div className="link">
                <a href="https://discord.gg/JcSAZUHkTE"> Discord </a>
              </div>
            </div>
          </div>
          <div className="row2">
            <div className="title">关注我们</div>
            <div className="img">
              <img src={wechatImg} alt="" />
            </div>
            <div className="wechat">微信公众号</div>
          </div>
        </div>
        <div className="email">
          联系我们：
          <a href="mailto:digitalcompoundgo@gmail.com">
            digitalcompoundgo@gmail.com
          </a>
        </div>
        <div className="right">{t('footer')}</div>
      </div>
    </footer>
  )
}

export const Layout: React.FC = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    const { search } = location
    if (!search) return
    const args = qs.parse(search.slice(1)) as { scroll: string }

    const offset = $(`#${args.scroll}`).offset()
    if (offset) {
      setTimeout(() => {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: offset.top,
          },
          300
        )
      }, 10)
    }
  }, [location])

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}
