import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import CommonHomeTile from '../../components/CommonHomeTitle'
import img1 from '../../assets/img/home_work_1.jpg'
import img2 from '../../assets/img/home_work_2.jpg'
import img3 from '../../assets/img/home_work_3.jpg'
import img4 from '../../assets/img/home_work_4.jpg'
import { useDetectMobile } from '../../utils'

interface HomeWorkData {
  key: string
  img: string
}

const data: HomeWorkData[] = [
  {
    key: '1',
    img: img1,
  },
  {
    key: '2',
    img: img2,
  },
  {
    key: '3',
    img: img3,
  },
  {
    key: '4',
    img: img4,
  },
]

interface HomeWorkProps {
  data: HomeWorkData
  opened: boolean
  onEnter: () => void
  onLeave: () => void
  onClick: () => void
}

const HomeWork: React.FC<HomeWorkProps> = ({
  data,
  opened,
  onEnter,
  onLeave,
  onClick,
}) => {
  const { t } = useTranslation('trans')
  const { key, img } = data

  return (
    <div
      className={classnames('work', { opened })}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      onClick={onClick}
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="work-content">
        <div className="work-title">{t(`home.work.${key}.title`)}</div>
        <div className="work-desc">{t(`home.work.${key}.desc`)}</div>
      </div>
      <div className="work-img">
        <img src={img} alt="" />
      </div>
    </div>
  )
}

export const HomeWorks: React.FC = () => {
  const [openId, setOpenId] = useState<number>()

  const [isMobile] = useDetectMobile()

  useEffect(() => {
    if (isMobile) setOpenId(0)
  }, [])

  const handleEnter = (id: number): void => {
    if (isMobile) return
    setOpenId(id)
  }

  const handleLeave = (id: number): void => {
    if (isMobile) return
    setOpenId(undefined)
  }

  const handleClick = (id: number): void => {
    if (!isMobile) return
    setOpenId(id)
  }

  return (
    <div className="works">
      <div className="container">
        <CommonHomeTile>WHAT WE DO 要做的事</CommonHomeTile>
        <div className="content">
          <div className="data">
            {data.map((item, i) => (
              <HomeWork
                data={item}
                key={item.key}
                opened={openId === i}
                onEnter={() => handleEnter(i)}
                onLeave={() => handleLeave(i)}
                onClick={() => handleClick(i)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
