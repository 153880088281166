import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import d1Img from '../../../assets/img/projects/digital-babel-library/d_1.png'
import d2Img from '../../../assets/img/projects/digital-babel-library/d_2.png'
import d3Img from '../../../assets/img/projects/digital-babel-library/d_3.png'
import d4Img from '../../../assets/img/projects/digital-babel-library/d_4.png'
import d5Img from '../../../assets/img/projects/digital-babel-library/d_5.png'
import d6Img from '../../../assets/img/projects/digital-babel-library/d_6.png'
import interpretImg from '../../../assets/img/projects/digital-babel-library/002.png'
import aboutImg from '../../../assets/img/projects/digital-babel-library/003.png'
import moreImg from '../../../assets/img/projects/digital-babel-library/more.png'
import { useDetectMobile } from '../../../utils'
import './style.scss'

const StyledBlcok = styled.div`
  margin: 100px 0;

  @media screen and (max-width: 600px) {
    margin: 75px 0;
  }
`

const About: React.FC = ({ children }) => {
  return (
    <div className="about container">
      <div className="content">
        <img src={aboutImg} alt="" />
        <div className="text">
          <div className="title">数字巴别图书馆：小门</div>
          <p className="high">
            概述：《数字巴别图书馆：小门》是巴别图书馆项目的一个重要前进节点。概括说来有两个特别重要的推进点：
          </p>
          <p>
            因为作品的表现手法真正用到了区块链技术。因此《数字巴别图书馆》这个项目成为了链上原生艺术。而不是上了链的数字艺术。
          </p>
          <p>
            《数字巴别图书馆：小门》所对应的文字内容是我对《巴别图书馆》的续写。在这之前，我所画内容是对博尔赫斯所写内容的可视化。
          </p>

          {/* TODO: */}
          <a className="more" href="" style={{ display: 'none' }}>
            <img src={moreImg} alt="" />
          </a>
        </div>
      </div>
    </div>
  )
}
const Interpret: React.FC = ({ children }) => {
  return (
    <div className="interpret container">
      <div className="top-img">
        <img src={interpretImg} alt="" />
      </div>
      <div className="desc">
        <p className="high">
          作品说明：我一直对加密艺术为什么要在线下呈现有困惑。
        </p>
        <p>
          从传播效率和观看体验的角度，加密艺术都是更偏向数字世界的。在脱域展筹备期间，我得出的阶段性答案是：美术馆作为一个经过筛选的信息环境，和较有仪式感的空间，很适合作为人们进入加密世界的传送门。于是我选择不在展厅里呈现作品的全部面貌，而是留下能够看到作品和我想法根基的二维码。
        </p>
        <p className="high">同时，我希望我的图书馆是一个公共物品。</p>
        <p>
          所以没有购买NFT的人也可以拷走源文件，甚至带走我创作时候的所有素材。对于愿意与我一起创建图书馆的朋友，我们还会互换作品NFT。展览期间，这件事情还真的发生了。
        </p>
      </div>
    </div>
  )
}
const Dynamics: React.FC = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // variableWidth: isMobile,
  }
  const [isMobile] = useDetectMobile()

  return (
    <div className="dynamics container">
      {isMobile ? (
        <Slider {...settings} className="issue-slide">
          <div className="issue">
            <img src={d1Img} alt="" />
          </div>
          <div className="issue">
            <img src={d2Img} alt="" />
          </div>
          <div className="issue">
            <img src={d3Img} alt="" />
          </div>
          <div className="issue">
            <img src={d4Img} alt="" />
          </div>
          <div className="issue">
            <img src={d5Img} alt="" />
          </div>
          <div className="issue">
            <img src={d6Img} alt="" />
          </div>
        </Slider>
      ) : (
        <div className="imgs">
          <img src={d1Img} alt="" />
          <img src={d2Img} alt="" />
          <img src={d3Img} alt="" />
          <img src={d4Img} alt="" />
          <img src={d5Img} alt="" />
          <img src={d6Img} alt="" />
        </div>
      )}
    </div>
  )
}

const ProjectDigitalBabelLibrary: React.FC = ({ children }) => {
  return (
    <div id="digitalBabelLibrary">
      <div className="theme-block">
        <div className="content container">
          <div className="t2">Digital Babel Library</div>
          <div className="t1">数字巴别图书馆白皮书</div>
          <div className="desc">
            <span>该项目的核心部分是用图像再造博尔赫斯文中的巴别图书馆。</span>
            这将是一个以数字造型（数字造型的概念包含但不限于数字绘画、数字雕刻）为主要呈现方式的艺术项目。造型之外，陈述、解释、白皮书书写、币的分发等行为也是作品的重要组成部分。
          </div>
          <div className="more">
            <a
              href="https://mp.weixin.qq.com/s?__biz=MzkzNzI4NzEyMg==&mid=2247484166&idx=1&sn=07ed9310484d90554411b5fc0d55c786&chksm=c2908fbbf5e706ad1acb16223f1e5704d755fa6492345c8f4cdfa3c7c4c4c651059666a8fd57#rd"
              target="_blank"
            >
              <button style={{ cursor: 'pointer' }}>进入白皮书</button>
            </a>
          </div>
        </div>
      </div>
      <div className="theme-block-ext">
        <div className="container">
          <div className="desc">
            <p className="high">
              该项目的核心部分是用图像再造博尔赫斯文中的巴别图书馆。
            </p>
            <p>
              这将是一个以数字造型（数字造型的概念包含但不限于数字绘画、数字雕刻）为主要呈现方式的艺术项目。造型之外，陈述、解释、白皮书书写、币的分发等行为也是作品的重要组成部分。
            </p>
          </div>
        </div>
      </div>
      <StyledBlcok>
        <div className="block-title font-dinpro-regular">
          <span>001</span>
        </div>
        <div className="block-subtitle font-dinpro-regular">
          DYNAMICS 数字巴别图书馆组件库
        </div>
        <Dynamics />
      </StyledBlcok>
      <StyledBlcok>
        <div className="block-title font-dinpro-regular">
          <span>002</span>
        </div>
        <div className="block-subtitle font-dinpro-regular">
          PORTAL 脱域展上的
          <br />
          《数字巴别图书馆：传送门》
        </div>
        <Interpret />
      </StyledBlcok>
      <StyledBlcok>
        <div className="block-title font-dinpro-regular">
          <span>003</span>
        </div>
        <div className="block-subtitle font-dinpro-regular">
          DOOR 数字巴别图书馆：小门
        </div>
        <About />
      </StyledBlcok>
    </div>
  )
}

export default ProjectDigitalBabelLibrary
