import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonHomeTitle from '../../components/CommonHomeTitle'
import img1 from '../../assets/img/home_team_1.jpg'
import img2 from '../../assets/img/home_team_2.jpg'
import img3 from '../../assets/img/home_team_3.jpg'
import img4 from '../../assets/img/home_team_4.jpg'

interface HomeMemberData {
  key: string
  img: string
}

const data: HomeMemberData[] = [
  {
    key: '1',
    img: img1,
  },
  {
    key: '2',
    img: img2,
  },
  {
    key: '3',
    img: img3,
  },
  {
    key: '4',
    img: img4,
  },
]

const HomeMember: React.FC<{ data: HomeMemberData }> = ({ data }) => {
  const { t } = useTranslation('trans')
  const { key, img } = data

  return (
    <div className="member">
      <div className="member-img">
        <img src={img} alt="" />
      </div>
      <div className="member-info">
        <div className="member-name">{t(`home.team.${key}.name`)}</div>
        <div className="member-title">{t(`home.team.${key}.title`)}</div>
        <div className="member-desc">{t(`home.team.${key}.desc`)}</div>
        <div className="member-alt-desc">{t(`home.team.${key}.alt_desc`)}</div>
      </div>
    </div>
  )
}

export const HomeTeam: React.FC = () => {
  return (
    <div className="team" id="team">
      <div className="container">
        <CommonHomeTitle>FOUNDING TEAM 创始团队</CommonHomeTitle>
        <div className="data">
          {data.map((item) => (
            <HomeMember data={item} key={item.key} />
          ))}
        </div>
      </div>
    </div>
  )
}
