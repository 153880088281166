import React from 'react'
import styled from 'styled-components'

const StyledHomeTitle = styled.div`
  text-align: center;
  font-size: 56px;
  margin-bottom: 50px;

  @media screen and (max-width: 600px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
`

const CommonHomeTile: React.FC = ({ children }) => {
  return (
    <StyledHomeTitle className="font-dinpro-regular">
      {children}
    </StyledHomeTitle>
  )
}

export default CommonHomeTile
