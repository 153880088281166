import React, { useEffect, useState } from 'react'
import CommonHomeTitle from '../../components/CommonHomeTitle'
import serverWalletAPI, { ResearchData } from '../../apis/ServerWalletAPI'
import CommonLink from '../../components/CommonLink'
import btnImg from '../../assets/img/home_research_btn.png'

const MAX_DESC_LENGTH = 80

const HomeResearch: React.FC<{ data: ResearchData }> = ({ data }) => {
  const { image, title, link } = data

  let desc = data.desc
  if (desc.length > MAX_DESC_LENGTH) {
    desc = desc.slice(0, MAX_DESC_LENGTH) + '...'
  }

  return (
    <div className="research">
      <div className="research-img">
        <img src={image} alt="" />
      </div>
      <div className="research-content">
        <div className="research-title">{title}</div>
        <div className="research-desc">{desc}</div>
        <div className="research-btn">
          <CommonLink to={link}>
            <img src={btnImg} alt="" />
          </CommonLink>
        </div>
        {/* <div className="research-opt">
          <span className="date">{t(`home.research.${key}.date`)}</span>
        </div> */}
      </div>
    </div>
  )
}

export const HomeResearchs: React.FC = () => {
  const [data, setData] = useState<ResearchData[]>([])

  useEffect(() => {
    serverWalletAPI
      .getResearchs()
      .then(setData)
      .catch((e) => console.log(e))
  }, [])

  return (
    <div className="researchs" id="researchs">
      <div className="container">
        <CommonHomeTitle>RESEARCH 研究</CommonHomeTitle>
        <div className="data">
          {data.map((item) => (
            <HomeResearch data={item} key={item.id} />
          ))}
        </div>
      </div>
    </div>
  )
}
