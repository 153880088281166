import React from 'react'
import CommonHomeTitle from '../../components/CommonHomeTitle'
import img1 from '../../assets/img/home_partner_1.jpg'
import img2 from '../../assets/img/home_partner_2.png'

const data = [img1, img2]

export const HomePartner: React.FC = () => {
  return (
    <div className="partners">
      <div className="container">
        <CommonHomeTitle>CRYPTO PARTNERS 加密伙伴</CommonHomeTitle>
        <div className="data">
          {data.map((img, i) => (
            <img key={i} src={img} alt="" />
          ))}
        </div>
      </div>
    </div>
  )
}
