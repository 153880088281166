import { useState } from 'react'
import { createContainer } from 'unstated-next'

interface useSystemStates {
  totalModified?: number
  currentHoverColor?: number
  currentSelectColor?: number
}

export interface useSystemProps {
  totalModified?: number
  setTotalModified: (totalModified?: number) => void
  currentHoverColor?: number
  setCurrentHoverColor: (currentHoverColor?: number) => void
  currentSelectColor?: number
  setCurrentSelectColor: (currentHoverColor?: number) => void
}

const defaultStates: useSystemStates = {}

function useSystem(customInitialStates: useSystemStates = {}): useSystemProps {
  const initialStates = {
    ...defaultStates,
    ...customInitialStates,
  }
  const [totalModified, setTotalModified] = useState(
    initialStates.totalModified
  )
  const [currentHoverColor, setCurrentHoverColor] = useState(
    initialStates.currentHoverColor
  )
  const [currentSelectColor, setCurrentSelectColor] = useState(
    initialStates.currentSelectColor
  )

  return {
    totalModified,
    setTotalModified,
    currentHoverColor,
    setCurrentHoverColor,
    currentSelectColor,
    setCurrentSelectColor,
  }
}

const System = createContainer(useSystem)

export default System
