import React from 'react'
// import classnames from 'classnames'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import img1 from '../../assets/img/home_theme_bg.png'
import img2 from '../../assets/img/projects/digital-babel-library/bg.png'

export const HomeTheme: React.FC = () => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // variableWidth: isMobile,
  }

  return (
    <Slider {...settings} className="theme-slide">
      <div className="theme-block">
        <div className="content" style={{ backgroundImage: `url(${img1})` }}>
          <div className="container">
            <div className="t1">
              加密艺术驱动器<span>Crypto Art Drive</span>
            </div>
            <div className="t2">
              用艺术表达什么是公链
              <span>Express what is the public chain in artistic way</span>
            </div>
            <div className="more">
              <Link to="/projects/crypto-art-driver">
                <button>了解详情</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-block">
        <div className="content" style={{ backgroundImage: `url(${img2})` }}>
          <div className="container">
            <div className="t1">数字巴别图书馆</div>
            <div className="t2">Digital Babel Library</div>
            <div className="more">
              <Link to="/projects/digital-babel-library">
                <button>了解详情</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  )
}
